import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from "./SectionTitle"
import { GatsbyImage } from "gatsby-plugin-image"

function ClientCard({ client }) {
  const img = (
    <GatsbyImage
      image={client.logo.childImageSharp.gatsbyImageData}
      className="w-full h-auto"
      alt={client.name}
    />
  )
  const className =
    "inline-block bg-white p-2 border hover:border-gray-700 transition duration-200 ease-in"

  if (client.url) {
    return (
      <a
        href={client.url}
        title={client.name}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {img}
      </a>
    )
  }

  return <div className={className}>{img}</div>
}

function Clients() {
  const data = useStaticQuery(graphql`
    {
      allClientsJson {
        edges {
          node {
            id
            name
            url
            logo {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="relative py-8 md:py-12 lg:pt-12 lg:pb-16">
      <div className="container relative z-10">
        <SectionTitle
          title="Our Clients"
          excerpt="For more than a decade we have helped clients ship products on multiple platforms. For Startups, For Fast Growing Companies and For Enterprises"
        />
        <div className="grid grid-cols-3 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 gap-3 pt-6">
          {data.allClientsJson.edges.map(({ node }) => {
            return <ClientCard key={node.id} client={node} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Clients
