import React from "react"
import classnames from "classnames"

/**
 * 
 * @param {{ labe: string, tags: string[], size: 'default'|'small'}} param0 
 */
function ProjectKeyTag({ label, tags, size = "default" }) {
  if (!tags) {
    return null
  }

  const labelClassName = classnames("block text-gray-700", {
    "text-sm lg:text-lg font-medium leading-5 lg:leading-6": size === "default",
    "text-xs font-medium sm:text-lg": size === "small",
  })

  const tagClassName = classnames("block  text-gray-900", {
    "text-lg lg:text-xl font-semibold leading-6 lg:leading-7": size === "default",
    "text-xs font-normal sm:text-lg": size === "small",
  })

  return (
    <div className="inline-block">
      <span className={labelClassName}>
        {label}
      </span>
      <span className={tagClassName}>{tags.join(", ")}</span>
    </div>
  )
}

export default ProjectKeyTag
