import React from "react"

/**
 * 
 * @param {{ title: React.ReactNode, excerpt: React.ReactNode }} param0 
 */
function SectionTitle({ title, excerpt }) {
  return (
    <div>
      <h2 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
        {title}
      </h2>
      {excerpt && <p className="text-lg pt-4 leading-6 max-w-5xl">{excerpt}</p>}
    </div>
  )
}

export default SectionTitle
