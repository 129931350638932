import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ProjectKeyTag from "./ProjectKeyTag"

function FeatureProjectCard({ project }) {
  return (
    <div
      className="bg-white border border-gray-200 md:flex"
      style={{ minHeight: 530 }}
    >
      <div className="relative pb-1/2 md:w-1/2 md:pb-0">
        <div className="absolute inset-0">
          <GatsbyImage
            image={
              project.frontmatter.coverImage.childImageSharp.gatsbyImageData
            }
            alt={project.frontmatter.title}
            className="w-full h-full object-cover block"
          />
        </div>
      </div>
      <div className="p-4 lg:p-5 xl:py-10 xl:px-8 md:w-1/2 flex flex-col justify-between">
        <div>
          <span className="block text-2xl lg:text-4xl font-medium text-gray-900 leading-8">
            {project.frontmatter.title}
          </span>
          <p className="text-base leading-6 lg:text-lg lg:leading-7 text-gray-800 pt-2 lg:pt-4">
            {project.excerpt}
          </p>
          <div className="pt-4">
            <Link
              className="inline-block py-1 leading-5 font-bold text-lg lg:text-1.5xl text-blue-700 hover:text-blue-800"
              to={`/projects/${project.frontmatter.slug}`}
            >
              Learn More →
            </Link>
          </div>
        </div>
        <div className="flex space-x-3 pt-6 sm:space-x-6 xl:space-x-9">
          <div>
            <ProjectKeyTag
              label="Industry:"
              tags={project.frontmatter.industry}
              size="small"
            />
          </div>
          <div>
            <ProjectKeyTag
              label="Key Features:"
              tags={project.frontmatter.features}
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureProjectCard
